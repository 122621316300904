try {
    window._ = require('lodash')
    window.bootstrap = require('bootstrap')
    window.$ = window.jQuery = require('jquery')
    require('jquery-validation')
    require('jquery-validation/dist/additional-methods.min.js')
    require('bootstrap-datepicker')
    require('datatables.net-bs5')
    require('datatables.net-responsive-bs5')
    require('@chartisan/chartjs')
    require('slick-carousel')
    require('slick-carousel/slick/slick.min.js')
    FusionCharts = require('fusioncharts')
    Charts = require('fusioncharts/fusioncharts.charts')
    FusionTheme = require('fusioncharts/themes/fusioncharts.theme.fusion')
    Widgets = require('fusioncharts/fusioncharts.widgets')
    Charts(FusionCharts)
    Widgets(FusionCharts)
    FusionTheme(FusionCharts)
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios')

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
